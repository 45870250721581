import "./bangleCeremony.css";
import { useNavigate } from "react-router-dom";

function BangleCeremony() {
  const navigate = useNavigate();

  const handleNextClick = () => {
    navigate("/bangle-ceremony-history");
  };

  return (
    <div
      style={{
        backgroundColor: "red",
        backgroundImage: `url("https://i.pinimg.com/originals/b6/a1/7c/b6a17ccfff94179e30ef7c33171362aa.gif")`,
        height: "200px",
        width: "100%",
      }}
    >
      <div className="invitation-container">
        <div className="image-container-bangle">
          {/* <img src="bangle-banner.png" alt="Bangles" className="bangle-image" /> */}
          <div className="invitation-section">
            <h1
              className="invite-header blinking-text "
              style={{ paddingTop: "60rem" }}
            >
              Welcome
            </h1>

            <img
              src="bangle-banner.png"
              alt="Bangles"
              className="bangle-image"
            />

            <h2 className="invitation-ptag underline">வளைகாப்பு</h2>
            <h2 className="invitation-ptag ">Saraswathi</h2>
            <h2 className="invitation-ptag  ">&</h2>
            <h2 className="invitation-ptag  ">Sridhar</h2>
          </div>
        </div>
      </div>
      <div
        class="button-container"
        style={{ paddingTop: "45rem", paddingBottom: "15rem" }}
      >
        <button className="next-button-bangle" onClick={handleNextClick}>
          Next
        </button>
      </div>
    </div>
  );
}

export default BangleCeremony;
