import React from "react";
import "./bangleCeremony.css";
import { useNavigate } from "react-router-dom";

function BangleCeremonyHistory() {
  const navigate = useNavigate();

  const handleNextClick = () => {
    navigate("/bangle-ceremony-questions");
  };

  return (
    <>
      <div class="button-container-1">
        <button className="next-button-bangle" onClick={handleNextClick}>
          Take a Quiz
        </button>
      </div>

      <div className="bangle-wrapper">
        <h1>வளைகாப்பு: வளையலணியுஞ் சடங்கு</h1>
        <div>
          <img
            src="https://www.shutterstock.com/image-photo/pre-wedding-ceremony-lady-bangle-600nw-2154284419.jpg"
            alt="Bangles"
            className="bangle-image"
          />
          <p>
            வளைகாப்புகளின் தோற்றம் தெளிவாக இல்லை, ஆனால் இது பண்டைய எகிப்து
            மற்றும் கிரேக்கத்தில் தொடங்கியதாக நம்பப்படுகிறது. புதிதாக ஒரு
            குழந்தை பிறந்ததும், தாய் 10-40 நாட்கள் தனிமையில் இருந்ததாகவும்,
            பின்னர் தனது புதிய குழந்தையை சமூகத்திற்கு வழங்குவதாகவும்
            கூறப்படுகிறது.
            <br />
            <br />
            சமூகம் தாய் மற்றும் குழந்தைக்கு பரிசுகளை கொண்டு வந்து புதிய வருகையை
            கொண்டாடும்.
            <br />
            <br />
            வளைகாப்பு நிகழ்ச்சியின் நோக்கம், ஒரு குழந்தையின் வரவிருக்கும்
            பிறப்பைக் கொண்டாடுவதும், புதிய பெற்றோருக்கு அவர்களின் புதிய
            வருகைக்குத் தயாராக உதவுவதும் ஆகும்.
            <br />
            <br />
            வளைகாப்பு பொதுவாக நெருங்கிய நண்பர் அல்லது குடும்ப உறுப்பினரால்
            வீசப்படுகிறது, மேலும் விருந்தினர் பட்டியலில் எதிர்பார்க்கும் தாயின்
            நண்பர்கள் மற்றும் குடும்ப உறுப்பினர்கள் உள்ளனர்.
            <br />
            <br />
            விருந்து ஒரு வீடு, உணவகம் அல்லது பூங்காவில் நடத்தப்படலாம், மேலும்
            விளையாட்டுகள், உணவு மற்றும் பரிசுகளை உள்ளடக்கியிருக்கலாம்.
            <br />
            <br />
            வளைகாப்பின் மற்றொரு நோக்கம், வரப்போகும் தாய் மற்றும் அவள்
            கர்ப்பத்தைக் கொண்டாடுவது.
            <br />
            <br />
            கர்ப்பம் என்பது பல பெண்களுக்கு ஒரு சவாலான நேரமாக இருக்கலாம், மேலும்
            வளைகாப்பு என்பது எதிர்பார்ப்புள்ள தாய்க்கு அவள் நேசிக்கப்படுகிறாள்
            மற்றும் ஆதரிக்கப்படுகிறாள் என்பதைக் காட்டுவதற்கான ஒரு வழியாகும்.
            <br />
            <br />
            வரப்போகும் தாயுடன் ஆலோசனைகளையும் அனுபவங்களையும்
            பகிர்ந்துகொள்வதற்கும் ஊக்கமளிக்கும் வார்த்தைகளை வழங்குவதற்கும் இது
            ஒரு வழியாகும்.
            <br />
            <br />
            இரத்த ஓட்டம் மற்றும் ஆற்றல்: பொதுவாக மணிக்கட்டு பகுதி எந்த
            மனிதரிடமும் தொடர்ந்து செயல்படும்.
            <br />
            <br />
            மேலும், இந்த பகுதியில் உள்ள துடிப்பு பெரும்பாலும் அனைத்து வகையான
            நோய்களுக்கும் சோதிக்கப்படுகிறது.
            <br />
            <br />
            பெண்கள் பயன்படுத்தும் வளையல்கள் பொதுவாக ஒருவரின் கையின் மணிக்கட்டுப்
            பகுதியின் உள்ளே இருக்கும், மேலும் அதன் நிலையான உராய்வு இரத்த ஓட்ட
            அளவை அதிகரிக்கிறது.
            <br />
            <br />
            மேலும், வெளித்தோல் வழியாக வெளியேறும் மின்சாரம், வளைய வடிவ
            வளையல்களின் காரணமாக மீண்டும் ஒருவரின் சொந்த உடலுக்குத்
            திரும்புகிறது,
            <br />
            <br />
            ஆற்றலை வெளியே அனுப்புவதற்கு எந்த முனையும் இல்லை, ஆனால் அதை மீண்டும்
            உடலுக்கு அனுப்பும்.
            <br />
            <br />
            கரு ஒலி தூண்டுதலுக்கு ஏங்குகிறது: வளையல்களின் டிங்கிள் குழந்தைக்கு
            ஒலி தூண்டுதல்களை வழங்குவதால்,
            <br />
            <br />
            வளைகாப்பின் போது பெண்களுக்கு வளையல்கள் பரிசளிக்கப்படுகின்றன.
            மகிழ்ச்சியான அல்லது அமைதியான இசை கர்ப்பிணிப் பெண்ணின் மன அழுத்தம்
            மற்றும் மனச்சோர்வைக் குறைக்கிறது
            <br />
            <br />
            மற்றும் கருவின் கேட்கும் திறனை வளர்க்க உதவுகிறது என்று ஆய்வுகள்
            காட்டுகின்றன.
            <br />
            <br />
            மன அழுத்தத்திற்கு ஆளாகும் கர்ப்பிணிப் பெண்களுக்கு குறைப்பிரசவம்
            அல்லது எடை குறைவான குழந்தை பிறக்கும் வாய்ப்பு அதிகம் என மகளிர்
            மருத்துவ நிபுணர்கள் கூறுகின்றனர்.
            <br />
            <br />
            உணர்ச்சி சமநிலை: கண்ணாடி வளையல் அணிந்த பெண்கள் மற்றும் பிற செயற்கைப்
            பொருட்களால் செய்யப்பட்ட வளையல்களை அணிந்தவர்கள் மீது மேற்கொள்ளப்பட்ட
            ஆராய்ச்சியில்,
            <br />
            <br />
            கண்ணாடி வளையல்கள் அதிர்வுறும் மற்றும் வலுவான உணர்ச்சிகளை
            அமைதிப்படுத்தும் மற்றும் மதிப்பீட்டாளர்களாக இருப்பதை
            வெளிப்படுத்தியது.
            <br />
            <br />
            கண்ணாடி அல்லாத வளையல்களை அணிந்திருப்பவர்கள், கொடுக்கப்பட்ட
            சூழ்நிலைகள் மற்றும் தூண்டுதலின் காரணமாக,
            <br />
            <br />
            அமைதியற்றவர்களாகவும், அதிக உணர்ச்சிவசப்படுபவர்களாகவும் இருப்பது
            கண்டறியப்பட்டது.
            <br />
            <br />
            நிறங்களின் சாரம்: இரண்டு வண்ண வளையல்கள் பரிந்துரைக்கப்படுகின்றன -
            சிவப்பு மற்றும் பச்சை.
            <br />
            <br />
            மகாராஷ்டிரா மற்றும் கர்நாடகா போன்ற தெற்கில் உள்ளவர்கள் பச்சை நிற
            கண்ணாடி வளையல்களை அணிவார்கள்.
            <br />
            <br />
            உ.பி., பஞ்சாப் போன்ற வடமாநில பெண்கள் சிவப்பு நிற ஆடைகளை அணிகின்றனர்.
            <br />
            <br />
            பச்சைக்கு அமைதியான மற்றும் துடிப்பான தெய்வீக குணம் உள்ளது, அதே
            நேரத்தில் சிவப்பு தீமையை அழிக்கும் சக்தி கொண்டது.
            <br />
            <br />
            தங்க நிற வேலைப்பாடுகளைக் கொண்ட வளையல்கள் அவ்வளவு பயனுள்ளதாக
            இருக்காது
            <br />
            <br />
            மற்றும் சாதாரண நிற கண்ணாடி வளையல்கள் செய்யும் பலனைக் கொடுக்காது.
          </p>
        </div>
      </div>
    </>
  );
}

export default BangleCeremonyHistory;
