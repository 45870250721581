import React, { useState } from "react";
import "./questions.css";

const Questions = () => {
  const questions = [
    {
      question: "Who is Sridhar's first brother-in-law?",
      options: ["Murali", "Gururajan", "Gopi"],
      answer: "Murali",
    },
    {
      question: "Who is Sridhar's periyamma?",
      options: ["Malar", "Kasthuri", "Geetha"],
      answer: "Malar",
    },
    {
      question: "Who is Saraswathi's mother?",
      options: ["Geetha", "Latha", "Kala"],
      answer: "Latha",
    },
    {
      question: "Who is Gunasekar's first younger brother?",
      options: ["Manohar", "Dhanapal", "Muthu"],
      answer: "Manohar",
    },
    {
      question: "Who is Sridhar's first athai?",
      options: ["Surya", "Kala", "Saraswathi"],
      answer: "Surya",
    },
    {
      question: "Who is Geetha's second brother?",
      options: ["Ravi", "Sampath", "Shekar"],
      answer: "Sampath",
    },
    {
      question: "Who is Yamini's first brother?",
      options: ["Murali", "Ashok", "Srini"],
      answer: "Ashok",
    },
    {
      question: "Who is Rajanya's first brother?",
      options: ["Mani", "Yeshwant", "Srini"],
      answer: "Yeshwant",
    },
    {
      question: "What did Sridhar study?",
      options: ["ECE", "EEE", "CSE", "IT"],
      answer: "EEE",
    },
    {
      question: "What did Yamini study?",
      options: ["ECE", "EEE", "CSE", "IT"],
      answer: "ECE",
    },
  ];

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showResult, setShowResult] = useState(false);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const handleNextQuestion = () => {
    if (selectedOption === questions[currentQuestion].answer) {
      setScore(score + 1);
    }

    setSelectedOption(null);

    if (currentQuestion + 1 < questions.length) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      setShowResult(true);
    }
  };

  const getResultMessage = () => {
    if (score === 10) {
      return "மிக சிறப்பு!!! நீங்கள் உறவினர்களை பற்றி நன்றாக அறிந்துள்ளீர்கள்";
    } else if (score >= 5 && score <= 9) {
      return "நல்ல முயற்சி!! நீங்கள் உறவினர்களை பற்றி தெரிந்து கொள்ள வேண்டும்";
    } else if (score >= 1 && score <= 4) {
      return "உறவுகளைப் பற்றி தெரிந்து கொள்ள அதிக நேரம் ஒதுக்குங்கள்";
    }
  };

  return (
    <div className="quiz-container">
      {!showResult ? (
        <>
          <h2 className="quiz-question">
            {questions[currentQuestion].question}
          </h2>
          <div className="options-container">
            {questions[currentQuestion].options.map((option, index) => (
              <button
                key={index}
                onClick={() => handleOptionClick(option)}
                className={`option-button ${
                  selectedOption === option ? "selected" : ""
                }`}
              >
                {option}
              </button>
            ))}
          </div>
          <button
            onClick={handleNextQuestion}
            className="next-button"
            disabled={!selectedOption}
          >
            {currentQuestion + 1 === questions.length ? "Submit" : "Next"}
          </button>
        </>
      ) : (
        <div className="result-container">
          <h2 className="result-header">
            Your score: {score}/{questions.length}
          </h2>
          <p className="result-message">{getResultMessage()}</p>
        </div>
      )}
    </div>
  );
};

export default Questions;
