import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './Components/Header';
import Hero from './Components/Hero';
import About from './Components/About';
import Services from './Components/Services';
import Contact from './Components/Contact';
import Footer from './Components/Footer';
import BangleCeremony from './Components/BangleCeremony';
import BangleCeremonyHistory from './Components/BangleCeremonyHistory';
import Questions from './Components/Questions';

const App = () => {
  useEffect(() => {
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener('click', function(e) {
        e.preventDefault();

        const targetId = this.getAttribute('href');
        const targetElement = document.querySelector(targetId);
        if (targetElement) {
          const headerHeight = document.querySelector('.header').offsetHeight;
          const offsetPosition = targetElement.offsetTop - headerHeight;

          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
          });
        }
      });
    });
  }, []);

  return (
    <Router>
      <div>
        <Routes>
          <Route 
            path="/" 
            element={
              <div className='quixent-bg'>
                <div id="home" className="section">
                  <Header />
                  <Hero />
                </div>
                <div id="about" className="section">
                  <About />
                </div>
                <div id="services" className="section">
                  <Services />
                </div>
                <div id="contact" className="section">
                  <Contact />
                </div>
                <Footer />
              </div>
            } 
          />
          <Route 
            path="/bangle-ceremony" 
            element={<BangleCeremony />}
          />
                    <Route 
            path="/bangle-ceremony-history" 
            element={<BangleCeremonyHistory />}
          />
                        <Route 
            path="/bangle-ceremony-questions" 
            element={<Questions />}
          />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
